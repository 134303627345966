import React from 'react';
import { Generic } from 'react-structured-data';
import publisher from './publisher';
import image from './image';

export default function blogPosting(post, mainEntityOfPage = false) {
  return (
    <Generic
      schema={{
        '@type': 'blogPosting',
        mainEntityOfPage,
        headline: post.title,
        datePublished: post.publishDate,
        dateModified: post.publishDate,
        author: 'Lumosity',
        articleBody: post.body.childMarkdownRemark.html
      }}
      key={post.slug}
    >
      {image(post.opengraphimage.openGraphCard)}
      {publisher()}
    </Generic>
  );
}
