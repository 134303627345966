import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { lumosBlack, lumosOrange, fontStyles } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';

// utils
import i18n from 'utils/i18n';

const styles = StyleSheet.create({
  separator: {
    color: lumosBlack,
    fontWeight: 800,
    marginLeft: 8,
    marginRight: 8
  },
  listElement: {
    listStyleType: 'none',
    display: 'inline'
  },
  link: {
    textTransform: 'uppercase',
    fontWeight: 800,
    color: lumosOrange,
    ':hover': {
      textDecoration: 'underline'
    }
  }
});

function BreadcrumbListItem({ title, target, separator, prependSeparator, locale, contentIndex, customStyles }) {
  const newTitle = title === locale ? 'home' : title;
  const breadCrumb = i18n.t(`BreadcrumbListItem.title.${newTitle}`);
  return (
    <li className={css(styles.listElement)} typeof="ListItem" property="itemListElement">
      {prependSeparator && <span className={css(fontStyles.h6, styles.separator)}>{separator}</span>}
      <Link
        property="item"
        typeof="Thing"
        to={target}
        data-track
        data-track-link-click={`breadcrumb-${title}`}
        data-track-location="body"
        className={css(fontStyles.h6, styles.link, ...customStyles)}
      >
        <span property="name">{breadCrumb}</span>
      </Link>
      <meta property="position" content={contentIndex} />
    </li>
  );
}

BreadcrumbListItem.propTypes = {
  separator: PropTypes.string.isRequired,
  locale: PropTypes.string,
  title: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  prependSeparator: PropTypes.bool.isRequired,
  contentIndex: PropTypes.number.isRequired,
  customStyles: PropTypes.arrayOf(PropTypes.object)
};

BreadcrumbListItem.defaultProps = {
  locale: 'en',
  customStyles: []
};

export default BreadcrumbListItem;
