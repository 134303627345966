import React from 'react';
import PropTypes from 'prop-types';
import { JSONLD } from 'react-structured-data';
import blogPosting from './shared/blogPosting';

export default function BlogPostingJSONLD({ post }) {
  return <JSONLD dangerouslyExposeHtml>{blogPosting(post, true)}</JSONLD>;
}

BlogPostingJSONLD.propTypes = {
  post: PropTypes.shape({
    headline: PropTypes.string,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    author: PropTypes.string,
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    slug: PropTypes.string,
    opengraphimage: PropTypes.shape({
      openGraphCard: PropTypes.shape({
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number
      })
    })
  }).isRequired
};
