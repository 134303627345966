import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbListItem from './BreadcrumbListItem';

const findBreadcrumbTargetPaths = path => {
  // remove leading and trailing /, split into component targets
  // then, remove pathway for current page (pop())
  const pathways = path.split('/').filter(pathItem => pathItem.length > 0);
  pathways.pop();

  return pathways;
};

function BreadcrumbList({ pathname, locale, separator, customStyles }) {
  const pathsToBuild = findBreadcrumbTargetPaths(pathname);
  if (!pathsToBuild.length) {
    return null;
  }
  return (
    <ol vocab="http://schema.org" typeof="BreadcrumbList">
      {pathsToBuild.map((path, idx) => (
        <BreadcrumbListItem
          title={path}
          target={`/${pathsToBuild.slice(0, idx + 1).join('/')}/`}
          locale={locale}
          separator={separator}
          prependSeparator={idx > 0}
          key={`${idx.toString()}-breadcrumb-${path}`}
          contentIndex={idx + 1}
          customStyles={customStyles}
        />
      ))}
    </ol>
  );
}

BreadcrumbList.propTypes = {
  separator: PropTypes.string,
  locale: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  customStyles: PropTypes.arrayOf(PropTypes.object)
};

BreadcrumbList.defaultProps = {
  separator: '/',
  customStyles: []
};

export default BreadcrumbList;
