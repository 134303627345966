import React from 'react'
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import i18n from '../../utils/i18n'

const styles = StyleSheet.create({
  socialSharecontainer: {
    margin: '0 auto',
    paddingBottom: 35,
    width: '200px',
  },
  icon: {
    cursor: 'pointer',
    display: 'inline-block',
    transform: 'scale(1.25)',
    width: '25%',
    textAlign: 'center'
  }
})

class SocialShareIcons extends React.Component {
  constructor(props) {
    super(props)
    this.state = { copied: false }
  }

  linkCopyText() {
    // if the text differs in character length, the tooltip anchor will not position over the share icon when the content is changed.
    // TODO: fix this 👆
    // Tried everything suggested here up til Jul 16 2018: https://github.com/wwayne/react-tooltip/issues/231
    const copiedState = this.state.copied ? 'postCopy' :  'preCopy'
    return i18n.t(`blog.socialShareIcons.copyLink.${copiedState}`)
  }

  render() {
    const { url, title, description } = this.props;
    return (
      <div className={css(styles.socialSharecontainer)}>
        <FacebookShareButton url={url} className={css(styles.icon)}>
          <FontAwesomeIcon icon={faFacebookF} data-tip data-for="facebook" />
        </FacebookShareButton>
        <ReactTooltip id="facebook" place="top" type="dark" effect="solid">
          <span>{i18n.t('blog.socialShareIcons.facebook')}</span>
        </ReactTooltip>
        <TwitterShareButton url={url} className={css(styles.icon)}>
          <FontAwesomeIcon icon={faTwitter} data-tip data-for="twitter" />
        </TwitterShareButton>
        <ReactTooltip id="twitter" place="top" type="dark" effect="solid">
          <span>{i18n.t('blog.socialShareIcons.twitter')}</span>
        </ReactTooltip>
        <EmailShareButton url={url} subject={title} body={description} className={css(styles.icon)}>
          <FontAwesomeIcon icon={faEnvelope} data-tip data-for="email" />
        </EmailShareButton>
        <ReactTooltip id="email" place="top" type="dark" effect="solid">
          <span>{i18n.t('blog.socialShareIcons.email')}</span>
        </ReactTooltip>
        <CopyToClipboard text={url} className={css(styles.icon)} onCopy={() => this.setState({ copied: true })}>
          <FontAwesomeIcon icon={faLink} data-tip data-for="copy-link" />
        </CopyToClipboard>
        <ReactTooltip id="copy-link" place="top" type="dark" effect="solid" getContent={() => this.linkCopyText()} />
      </div>
    )
  }
}

SocialShareIcons.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default SocialShareIcons
