import React from 'react';
import get from 'lodash/get';
import Img from 'gatsby-image';
import { Container } from 'reactstrap';
import { blue2, blue4, lumosBlack } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BreadcrumbList from 'components/ui/navigation/BreadcrumbList';
import Layout from 'components/layout';
import SubNav from 'components/sub-nav';
import BlogPostingJSONLD from 'components/blog/SchemaDotOrg/blogPostingJSONLD';
import SocialShareIcons from 'components/blog/SocialShareIcons';
import Metatags from 'components/Metatags';
import i18n from 'utils/i18n';

const styles = StyleSheet.create({
  wrapper: {
    '@media (min-width: 768px)': {
      width: '544px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },

    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15
    }
  },
  date: {
    color: blue2,
    textAlign: 'center',
    marginTop: '35px',
    '@media (min-width: 992px)': {
      marginTop: '60px'
    }
  },
  entryTitle: {
    textAlign: 'center',
    lineHeight: '1.4em',
    letterSpacing: '.04px',
    fontWeight: 'normal',
    marginBottom: '40px',
    '@media (min-width: 992px)': {
      fontSize: '2.5em'
    },
    '@media (min-width: 768px)': {
      marginBottom: '60px'
    }
  },
  aboveHero: {
    fontSize: '18px',
    lineHeight: '1.8em'
  },
  table: {
    display: 'table',
    margin: '0 auto'
  },
  tagList: {
    textAlign: 'center',
    margin: '35px 0'
  },
  tag: {
    paddingLeft: '10px',
    paddingRight: '10px',
    color: blue2,
    fontSize: '18px',
    textDecoration: 'none'
  },
  relatedArticlesList: {
    margin: 0
  },
  breadcrumbListItem: {
    color: blue4
  },
  bodyContent: {
    marginTop: '35px',
    fontSize: '1em',
    color: '#555555',
    '@media (min-width: 992px)': {
      marginTop: '45px'
    },
    ':nth-child(1n) > p': {
      fontSize: '1em',
      '@media (min-width: 415px)': {
        fontSize: '1.25em'
      }
    },
    ':nth-child(1n) > h1': {
      fontSize: '1.75em',
      fontWeight: 'bold',
      '@media (min-width: 992px)': {
        fontSize: '2em'
      }
    },
    ':nth-child(1n) > h2': {
      fontSize: '1.5em',
      fontWeight: 'bold',
      '@media (min-width: 992px)': {
        fontSize: '1.75em'
      }
    },
    ':nth-child(1n) > h3': {
      fontSize: '1.15em',
      fontWeight: 'bold',
      '@media (min-width: 992px)': {
        fontSize: '1.25em'
      }
    },
    ':nth-child(1n) a': {
      color: blue4,
      transition: 'color 100ms ease-in-out',
      textDecoration: 'none'
    },
    ':nth-child(1n) a:hover': {
      color: lumosBlack
    }
  }
});
class BlogPostTemplate extends React.Component {
  static showHero(post) {
    if (!post.heroImage) {
      return null;
    }
    return <Img fluid={post.heroImage.fluid} alt={post.title} />;
  }

  static showRelatedArticles(relatedArticles, locale) {
    if (!relatedArticles) {
      return null;
    }
    return (
      <div>
        {i18n.t('blogPost.relatedArticles')}:
        <ul className={css(styles.relatedArticlesList)}>
          {relatedArticles.map((article) => (
            <li key={article.slug}>
              <Link className={css(styles.relatedArticle)} alt={article.title} to={`/${locale}/blog/${article.slug}`}>
                {article.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  static showTags(tags, locale) {
    if (!tags) {
      return null;
    }
    return (
      <div className={css(styles.tagList)}>
        {tags.map((tag) => {
          const tagSlug = tag.toLowerCase().replace(/\s+/g, '-');
          return (
            <Link key={tagSlug} className={css(styles.tag)} to={`/${locale}/blog/topic/${tagSlug}`}>
              {tag}
            </Link>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      location,
      data: { contentfulBlogPost: post },
      pageContext: { pagePath, locale: pageLocale }
    } = this.props;
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl');
    const currentUrl = `${siteUrl}${location.pathname}`;
    const { node_locale: locale } = post;
    return (
      <>
        <Layout locale={locale} location={location} footerOptions={{ renderLanguageLinks: false }} noGutters>
          <SubNav title={i18n.t('blog.subNav.title')} useH1={false} to={`/${locale}/blog/`} locale={locale} />
          <div>
            <Container>
              <BreadcrumbList
                pathname={location.pathname}
                locale={locale}
                separator=">"
                customStyles={[styles.breadcrumbListItem]}
              />
            </Container>
            <div className={css(styles.wrapper)}>
              <div className={css(styles.date)}>{post.publishDate}</div>
              <h1 className={css(styles.entryTitle)}>{post.title}</h1>
              <h2 className={css(styles.aboveHero)}>{post.aboveHero}</h2>
              <Metatags
                title={post.title}
                description={post.description.description}
                thumbnail={post.opengraphimage.openGraphCard.src}
                imageWidth={post.opengraphimage.openGraphCard.width}
                imageHeight={post.opengraphimage.openGraphCard.height}
                twitterImage={post.opengraphimage.twitterImage.url}
                type="article"
                canonical={pagePath}
                locale={pageLocale}
              />
              <div>{BlogPostTemplate.showHero(post)}</div>
              <div
                className={css(styles.bodyContent)}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html
                }}
              />
              {BlogPostTemplate.showRelatedArticles(post.relatedArticles, locale)}
            </div>
            {BlogPostTemplate.showTags(post.tags, locale)}
            <SocialShareIcons
              url={currentUrl}
              title={post.title}
              description={post.description.description}
              className={css(styles.shareIcons)}
            />
          </div>
        </Layout>
        <BlogPostingJSONLD post={post} />
      </>
    );
  }
}

export default BlogPostTemplate;

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape({
      post: PropTypes.shape({
        node: PropTypes.shape({
          heroImage: PropTypes.shape({
            openGraphCard: PropTypes.shape({
              src: PropTypes.string,
              height: PropTypes.number,
              width: PropTypes.number
            })
          })
        })
      })
    })
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }, node_locale: { eq: "en" }) {
      title
      slug
      publishDate(formatString: "MMM D, YYYY")
      tags
      relatedArticles {
        title
        slug
      }
      node_locale
      aboveHero
      opengraphimage {
        openGraphCard: fixed(width: 1200, height: 630) {
          aspectRatio
          width
          height
          src
        }
        twitterImage: file {
          url
        }
      }
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
        openGraphCard: fixed(width: 1200, height: 630) {
          aspectRatio
          width
          height
          src
        }
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
