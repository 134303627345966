import React from 'react';
import { Generic } from 'react-structured-data';

export default function publisher() {
  return (
    <Generic
      type="publisher"
      jsonldtype="Publisher"
      schema={{
        '@type': 'Organization',
        name: 'Lumos Labs (Lumosity)'
      }}
    >
      <Generic
        type="logo"
        jsonldtype="Logo"
        schema={{
          '@type': 'imageObject',
          name: 'Lumosity Logo',
          url: 'https://asset.lumosity.com/resources/static/press/logos/lumosity_logo.png',
          height: 49,
          width: 191
        }}
      />
    </Generic>
  );
}
