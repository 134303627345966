import React from 'react';
import { Generic } from 'react-structured-data';

export default function image(openGraphCard) {
  return (
    <Generic
      type="image"
      jsonldtype="Image"
      schema={{
        '@type': 'imageObject',
        url: `https:${openGraphCard.src}`,
        height: openGraphCard.height,
        width: openGraphCard.width
      }}
    />
  );
}
